import React, { useContext, useEffect, useState } from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons'
import { Card, Col, Row, Statistic } from 'antd'
import { Web3Context } from 'utils/Web3Provider'

import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import styles from 'assets/jss/pages/Home/statisticSectionStyle'
import globalStyles from 'assets/jss/PLUTEX'

const { Countdown } = Statistic

const useStyles = makeStyles(styles)
const useGlobalStyles = makeStyles(globalStyles)

const StatisticSection = () => {
  const classes = useStyles()
  const globalClasses = useGlobalStyles()

  const deadline = new Date('2024-04-06T16:00:00')

  const { connectionStatus, wrongNetwork, notifyLabel, account, walletInstalledStatus, loadWeb3, nftToken } =
    useContext(Web3Context)

  const [myMiningSpeed, setMyMiningSpeed] = useState(0)

  const basicSpeed = 16244

  function ipfsToHttp(ipfsUrl) {
    return `https://sirens.mypinata.cloud/ipfs/${ipfsUrl.replace('ipfs://', '')}`
  }

  function getMiningWeight(rarity) {
    if (rarity == 'Gold') {
      return 9.36
    } else if (rarity == 'Silver') {
      return 2.42
    } else if (rarity == 'Bronze') {
      return 1.73
    } else if (rarity == 'Iron') {
      return 1.23
    } else if (rarity == 'Wood') {
      return 1
    } else {
      return 0
    }
  }

  useEffect(() => {
    const fetchDatas = async () => {
      if (
        account != undefined &&
        account.address != undefined &&
        nftToken != null &&
        connectionStatus &&
        wrongNetwork == false
      ) {
        try {
          const address = account.address

          //myNfts
          const balance = await nftToken.methods.balanceOf(address).call()
          if (balance == 0) {
            return
          }

          // 使用 Promise.all() 等待所有请求完成
          const tokenURIs = []
          for (let i = 0; i < balance; i++) {
            const tokenId = await nftToken.methods.tokenOfOwnerByIndex(address, i).call()
            const tokenURI = await nftToken.methods.tokenURI(tokenId).call()
            tokenURIs.push(tokenURI)
          }

          const responses = await Promise.all(
            tokenURIs.map(tokenURI => {
              const fetchPromise = fetch(ipfsToHttp(tokenURI))
              const timeoutPromise = new Promise((resolve, reject) => {
                setTimeout(() => reject(new Error('Fetch timeout')), 10000) // 设置10秒超时
              })
              return Promise.race([fetchPromise, timeoutPromise])
            })
          )

          let miningWeights = await Promise.all(
            responses.map(async response => {
              if (response && response.ok) {
                const data = await response.json()
                const rarity = data.attributes[0].value
                return getMiningWeight(rarity)
              } else {
                console.log(response)
                return 0
              }
            })
          )

          let _miningSpeed = 0
          miningWeights.forEach(function (weight) {
            _miningSpeed += parseInt((weight * basicSpeed).toFixed(0))
          })
          setMyMiningSpeed(_miningSpeed)
        } catch (Error) {}
      }
    }
    fetchDatas()
  }, [nftToken, connectionStatus, wrongNetwork])

  return (
    <>
      <Box className={classes.statisticSectionArea}>
        <Box className={classes.quantityContainer}>
          <Box className={classes.container}>
            <Grid container display="flex" alignItems="center" justifyContent="center">
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box className={classes.titleBottom}>
                  <Typography className={clsx(classes.title, classes.quantityTitle)}>Epoch 01</Typography>
                </Box>
                <Box className={classes.textContentArea}>
                  <Row gutter={16}>
                    <Col span={24}>
                      <Card bordered={false}>
                        <Statistic title="$SRT Max Supply" value={1000000000} valueStyle={{ color: '#7A2F33' }} />
                      </Card>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Card bordered={false}>
                        <Statistic title="Basic Mining Supply" value={400000000} valueStyle={{ color: '#7A2F33' }} />
                      </Card>
                    </Col>
                    <Col span={12}>
                      <Card bordered={false}>
                        <Statistic title="Stake Mining Supply" value={400000000} valueStyle={{ color: '#7A2F33' }} />
                      </Card>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Card bordered={false}>
                        <Countdown
                          title="Burn Countdown Timer"
                          value={deadline}
                          valueStyle={{ color: '#F67480', fontWeight: 'bolder' }}
                        />
                      </Card>
                    </Col>
                    <Col span={12}>
                      <Card bordered={false}>
                        <Statistic
                          title="My Mining Speed"
                          value={`${myMiningSpeed} $SRT / WEEK`}
                          valueStyle={{ color: '#F67480', fontWeight: 'bolder' }}
                        />
                      </Card>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={24}>
                      <Card bordered={false}>
                        <Statistic title="Mining Start Time" value={'TBA'} valueStyle={{ fontWeight: 'bolder' }} />
                      </Card>
                    </Col>
                  </Row>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Box></Box>
    </>
  )
}

export default StatisticSection
