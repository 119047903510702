import React, { useContext, useEffect, useState, useMemo } from 'react'
import { Box, Grid, Typography, Paper } from '@material-ui/core'
import { NFTImage } from '@ant-design/web3'
// images
import sirenImg from 'assets/images/Home/feature.png'
import clsx from 'clsx'
import { makeStyles, withStyles, TextField, Button } from '@material-ui/core'
import styles from 'assets/jss/pages/Home/buyNowSectionStyle'
import globalStyles from 'assets/jss/PLUTEX'
import toast from 'react-hot-toast'
import { Web3Context } from 'utils/Web3Provider'
const BigNumber = require('bignumber.js')
import { REACT_APP_NETWORK_NAME, NFT_DISCOUNT_PRICE, NFT_NORMAL_PRICE } from 'utils/config'

const useStyles = makeStyles(styles)
const useGlobalStyles = makeStyles(globalStyles)

const AT_LEAST_ONE_SIZE = 'You must input any Size great than 0'
const NO_LEFT_NFTS = 'No left NFTs'
const WRONG_NETWORK = 'You should connect to the ' + REACT_APP_NETWORK_NAME
const WAIT_METAMASK = 'Please wait a moment.'
const SUCCESS_BUY = 'Successfully buy'

const BuyButton = withStyles(() => ({
  root: {
    color: '#FFFFFF',
    backgroundColor: '#000000',
    '&:hover': {
      backgroundColor: '#4c4c4c',
    },
    fontWeight: '700',
    borderRadius: '25px',
    padding: '10px 15px',
    fontFamily: 'sans-serif',
    // textTransform: 'none',
    width: '130px',
    margin: '20px',
  },
}))(Button)

const BuyNowSection = () => {
  const classes = useStyles()
  const globalClasses = useGlobalStyles()

  const basicSpeed = 16244

  const { connectionStatus, wrongNetwork, notifyLabel, account, walletInstalledStatus, loadWeb3, nftToken } =
    useContext(Web3Context)

  const [freeSize, setFreeSize] = useState('')
  const [discountSize, setDiscountSize] = useState('')
  const [normalSize, setNormalSize] = useState('')

  const [progressStatus, setProgressStatus] = useState(false)

  const [metadatas, setMetadatas] = useState([])

  //contract state
  const [freeLimit, setFreeLimit] = useState(0)
  const [discountLimit, setDiscountLimit] = useState(0)
  const [normalLimit, setNormalLimit] = useState(0)
  const [maxSupply, setMaxSupply] = useState(0)
  const [availableCount, setAvailableCount] = useState(0)
  const [freeMintedCount, setFreeMintedCount] = useState(0)
  const [discountMintedCount, setDiscountMintedCount] = useState(0)
  const [normalMintedCount, setNormalMintedCount] = useState(0)

  const discountPriceFormatted = new BigNumber(NFT_DISCOUNT_PRICE).toFixed()
  const normalPriceFormatted = new BigNumber(NFT_NORMAL_PRICE).toFixed()

  function ipfsToHttp(ipfsUrl) {
    return `https://sirens.mypinata.cloud/ipfs/${ipfsUrl.replace('ipfs://', '')}`
  }

  function getMiningWeight(rarity) {
    //12:8:5:2:1
    if (rarity == 'Gold') {
      return 9.36
    } else if (rarity == 'Silver') {
      return 2.42
    } else if (rarity == 'Bronze') {
      return 1.73
    } else if (rarity == 'Iron') {
      return 1.23
    } else if (rarity == 'Wood') {
      return 1
    } else {
      return 0
    }
  }

  useEffect(() => {
    if (!connectionStatus) {
      loadWeb3()
    }
  }, [])

  const targetTime = 1711872000000 // 设置目标时间点 2024-03-31 16:00:00 UTF+8
  const [mintDisabled, setMintDisabled] = useState(true)

  useEffect(() => {
    const intervalId = setInterval(() => {
      const currentTime = Date.now()
      if (currentTime >= targetTime) {
        setMintDisabled(false) // 如果当前时间超过目标时间点，更新按钮的禁用状态为 false
        clearInterval(intervalId) // 清除定时器，避免重复执行
      }
    }, 1000) // 每秒钟检查一次当前时间是否已经超过目标时间点

    return () => {
      clearInterval(intervalId) // 组件卸载时清除定时器
    }
  }, [targetTime])

  useEffect(() => {
    let balance = 0
    const fetchDatas = async () => {
      if (
        account != undefined &&
        account.address != undefined &&
        nftToken != null &&
        connectionStatus &&
        wrongNetwork == false
      ) {
        try {
          const address = account.address
          nftToken.methods.getMintState(address).call((error, result) => {
            if (error) {
              console.error('Error:', error)
            } else {
              setMaxSupply(parseInt(result.maxSupply))

              if (result.isWhitelist) {
                setFreeLimit(parseInt(result.freeLimit))
              } else {
                setFreeLimit(0)
              }
              if (result.meetsAssetRequirements) {
                setDiscountLimit(parseInt(result.discountLimit))
              } else {
                setDiscountLimit(0)
              }
              setNormalLimit(parseInt(result.normalLimit))

              setAvailableCount(parseInt(0))
              setFreeMintedCount(result.freeMintedCount)
              setDiscountMintedCount(result.discountMintedCount)
              setNormalMintedCount(result.normalMintedCount)
            }
          })

          //myNfts
          const _balance = await nftToken.methods.balanceOf(address).call()
          if (_balance == balance) {
            return
          } else {
            balance = _balance
          }

          // 使用 Promise.all() 等待所有请求完成
          const tokenURIs = []
          for (let i = 0; i < _balance; i++) {
            const tokenId = await nftToken.methods.tokenOfOwnerByIndex(address, i).call()
            const tokenURI = await nftToken.methods.tokenURI(tokenId).call()
            tokenURIs.push(tokenURI)
          }

          const responses = await Promise.all(
            tokenURIs.map(tokenURI => {
              const fetchPromise = fetch(ipfsToHttp(tokenURI))
              const timeoutPromise = new Promise((resolve, reject) => {
                setTimeout(() => reject(new Error('Fetch timeout')), 10000) // 设置10秒超时
              })
              return Promise.race([fetchPromise, timeoutPromise])
            })
          )

          let metadatas = await Promise.all(
            responses.map(async response => {
              if (response && response.ok) {
                const data = await response.json()
                const id = data.name.match(/\d+/)[0]
                const _rarity = data.attributes[0].value
                const miningSpeed = (getMiningWeight(_rarity) * basicSpeed).toFixed(0);
                return {
                  tokenId: id,
                  imageUrl: ipfsToHttp(data.image),
                  rarity: _rarity,
                  miningSpeed: miningSpeed,
                }
              } else {
                console.log(response)
                return null
              }
            })
          )

          metadatas = metadatas.filter(metadata => metadata !== null)
          setMetadatas(metadatas)
        } catch (error) {
          console.error('Error fetching data:', error)
        }
      }
    }

    // Fetch metadata initially
    fetchDatas()

    // Schedule fetch every 6 seconds
    const intervalId = setInterval(fetchDatas, 6000)

    // Cleanup function
    return () => clearInterval(intervalId)
  }, [nftToken, connectionStatus, wrongNetwork]) // useEffect 依赖数组为空，表示只在组件挂载和卸载时执行

  useEffect(() => {
    if (!walletInstalledStatus)
      window.open('https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en', '_blank')
  }, [walletInstalledStatus])

  const parseIntOrZero = str => {
    if (!isNaN(parseInt(str))) {
      return parseInt(str)
    } else {
      return 0
    }
  }

  const handleClickBuy = () => {
    if (!connectionStatus || account == undefined) {
      toast.error(WRONG_NETWORK)
      return
    }
    if (mintDisabled) {
      toast.error('Please wait mint start!')
      return
    }

    const address = account.address

    // nftToken.methods.withdraw().send({ from: address })
    // return

    let fSize = parseIntOrZero(freeSize)
    let cSize = parseIntOrZero(discountSize)
    let nSize = parseIntOrZero(normalSize)

    let sumSize = fSize + cSize + nSize
    if (sumSize == 0) {
      toast.error(AT_LEAST_ONE_SIZE)
      return
    }
    if (sumSize > availableCount) {
      toast.error(NO_LEFT_NFTS)
      return
    }

    const cPrice = new BigNumber(NFT_DISCOUNT_PRICE)
    const nPrice = new BigNumber(NFT_NORMAL_PRICE)
    const sumPrice = new BigNumber(cSize).times(cPrice).plus(new BigNumber(nSize).times(nPrice)).toFixed()

    toast.success(WAIT_METAMASK)
    setProgressStatus(true)

    window.web3.eth.getGasPrice().then(_gasPrice => {
      nftToken.methods
        .mint(fSize, cSize, nSize)
        .send({ from: address, gasPrice: _gasPrice * 0.7, value: window.web3.utils.toWei(sumPrice.toString()) })
        .then(data => {
          if (data.status) {
            toast.success(SUCCESS_BUY)
            setProgressStatus(false)
            setFreeSize('0')
            setDiscountSize('0')
            setNormalSize('0')
          }
        })
        .catch(error => {
          setProgressStatus(false)
          toast.error(error)
        })
    })
  }

  const handleChangeFree = event => {
    const reg = /^\d+$/
    let inputValue = event.target.value
    if (inputValue === '') {
      setFreeSize('0')
    } else if (reg.test(inputValue)) {
      if (parseFloat(inputValue) <= 0) {
        setFreeSize('0')
      } else if (parseFloat(inputValue) <= freeLimit - freeMintedCount) {
        setFreeSize(parseFloat(inputValue) + '')
      } else {
        setFreeSize('' + (freeLimit - freeMintedCount))
      }
    }
  }

  const handleChangeDiscount = event => {
    const reg = /^\d+$/
    let inputValue = event.target.value
    if (inputValue === '') {
      setDiscountSize('0')
    } else if (reg.test(inputValue)) {
      if (parseFloat(inputValue) <= 0) {
        setDiscountSize('0')
      } else if (parseFloat(inputValue) <= discountLimit - discountMintedCount) {
        setDiscountSize(parseFloat(inputValue) + '')
      } else {
        setDiscountSize('' + (discountLimit - discountMintedCount))
      }
    }
  }

  const handleChangeNormal = event => {
    const reg = /^\d+$/
    let inputValue = event.target.value
    if (inputValue === '') {
      setNormalSize('0')
    } else if (reg.test(inputValue)) {
      if (parseFloat(inputValue) <= 0) {
        setNormalSize('0')
      } else if (parseFloat(inputValue) <= normalLimit - normalMintedCount) {
        setNormalSize(parseFloat(inputValue) + '')
      } else {
        setNormalSize('' + (normalLimit - normalMintedCount))
      }
    }
  }

  const renderedItems = useMemo(
    () =>
      metadatas.map((metadata, index) => (
        <Grid item key={index} xs={12} sm={6} md={4} lg={2}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <NFTImage src={metadata.imageUrl} width={200} />
            <Typography variant="body1" align="center">
              ID: {metadata.tokenId}
            </Typography>
            <Typography variant="body1" align="center">
              Rarity: <span style={{ fontWeight: 'bolder' }}>{metadata.rarity}</span>
            </Typography>
            <Typography variant="body1" align="center">
              Speed: <span style={{ fontWeight: 'bolder' }}>{metadata.miningSpeed}</span>
            </Typography>
          </div>
        </Grid>
      )),
    [metadatas]
  )

  return (
    <>
      <Box className={classes.buyNowSectionArea}>
        <Box className={classes.quantityContainer}>
          <Box className={classes.container}>
          <Typography className={clsx(classes.title, classes.quantityTitle)}>Get Your MerlinSirens!</Typography>
            <Grid style={{marginTop: '30px'}} container display="flex" alignItems="center" justifyContent="center">
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Box display="flex" justifyContent="center" mb={5}>
                  <img src={sirenImg} className={classes.sirenImg} />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box className={classes.textContentArea}>
                  <Box>
                    <Typography style={{ marginBottom: '-20px' }}>
                      Free: {freeMintedCount}/{freeLimit}
                    </Typography>
                    <TextField
                      id="free-mint-size"
                      label="Free Mint Size"
                      variant="outlined"
                      value={freeSize}
                      type="number"
                      className={classes.textQuantity}
                      onChange={handleChangeFree}
                    />
                  </Box>
                  <Box style={{ marginTop: '10px' }}>
                    <Typography style={{ marginBottom: '-20px' }}>
                      Discount: {discountMintedCount}/{discountLimit}
                    </Typography>
                    <TextField
                      id="discount-mint-size"
                      label="Discount Mint Size"
                      variant="outlined"
                      value={discountSize}
                      type="number"
                      className={classes.textQuantity}
                      onChange={handleChangeDiscount}
                    />
                  </Box>
                  <Box style={{ marginTop: '10px' }}>
                    <Typography style={{ marginBottom: '-20px' }}>
                      Normal: {normalMintedCount}/{normalLimit}
                    </Typography>
                    <TextField
                      id="normal-mint-size"
                      label="Normal Mint Size"
                      variant="outlined"
                      value={normalSize}
                      type="number"
                      className={classes.textQuantity}
                      onChange={handleChangeNormal}
                    />
                  </Box>
                </Box>
                {connectionStatus && !wrongNetwork && (
                  <Box className={classes.textContentArea}>
                    <Typography style={{ marginBottom: '5px' }}>
                      Total Minted:{' '}
                      <span style={{ fontWeight: 'bolder' }}>
                        {maxSupply - availableCount}/{maxSupply}
                      </span>
                    </Typography>
                    <Typography>
                      Discount Price: <span style={{ fontWeight: 'bolder' }}>{discountPriceFormatted}</span> BTC
                    </Typography>
                    <Typography>
                      Normal Price:&nbsp;&nbsp; <span style={{ fontWeight: 'bolder' }}>{normalPriceFormatted}</span> BTC
                    </Typography>
                  </Box>
                )}
                <Box className={classes.textContentArea} style={{ marginTop: '-10px' }}>
                  <BuyButton
                    variant="contained"
                    className={classes.roadmap}
                    onClick={handleClickBuy}
                    disabled={mintDisabled || progressStatus || availableCount <= 0}
                  >
                    {availableCount <= 0 && maxSupply > 0 ? 'Sold out' : 'Mint!'}
                  </BuyButton>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box className={classes.gameContainer}>
          <Box className={classes.container}>
            <Box className={classes.content}>
              <Typography className={classes.title}>MY SIRENS</Typography>
              <Grid container spacing={4} className={classes.imgGrid}>
                {renderedItems}
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box></Box>
    </>
  )
}

export default BuyNowSection
