import React from 'react'
import Web3 from 'web3'
import NFT from 'abis/NFT.json'
import { REACT_APP_NETWORK_NAME, REACT_APP_CHAINID, REACT_APP_RPC_URL, REACT_APP_BLOCK_EXPLORE_URL, REACT_APP_NFT_CONTRACT_ADDRESS } from 'utils/config'

const DISCONNECTED = 'You should connect to the Wallet'
const WRONG_NETWORK = 'You should connect to the ' + REACT_APP_NETWORK_NAME
const WRONG_ACCOUNT = "You don't have any account on the " + REACT_APP_NETWORK_NAME
const SUCCSESS_CONNECTED = 'Successfully connected to the ' + REACT_APP_NETWORK_NAME
const METAMASK_UNINSTALLED = 'Non-Merlin browser detected. You should consider trying MetaMask!'

const NETWORK_NAME = REACT_APP_NETWORK_NAME
const CHAIN_ID = REACT_APP_CHAINID
const RPC_URL = REACT_APP_RPC_URL
const BLOCK_EXPLORE_URL = REACT_APP_BLOCK_EXPLORE_URL

export const Web3Context = React.createContext()

export class Web3Provider extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      connectionStatus: null,
      wrongNetwork: null,
      notifyLabel: '',
      account: undefined,
      walletInstalledStatus: true,
      nftToken: null,
    }
  }

  async componentDidMount() {}

  loadWeb3 = async () => {
    let self = this
    if (window.ethereum) {
      // register handlers
      window.ethereum.on('chainChanged', function (chain) {
        if (chain !== CHAIN_ID) {
          self.setState({ ...self.state, connectionStatus: false, wrongNetwork: true, notifyLabel: WRONG_NETWORK })
        } else {
          self.getBlockchainData()
        }
      })
      window.ethereum.on('accountsChanged', function (accounts) {
        if (accounts === undefined || accounts[0] === undefined) {
          self.setState({ ...self.state, connectionStatus: false, notifyLabel: WRONG_ACCOUNT })
        }
        if (window.ethereum.chainId !== CHAIN_ID) {
          self.setState({ ...self.state, connectionStatus: false, wrongNetwork: true, notifyLabel: WRONG_NETWORK })
        } else {
          self.getBlockchainData()
        }
      })
      window.ethereum.on('disconnect', function () {
        self.disconnect()
      })
      window.web3 = new Web3(window.ethereum)
      await window.ethereum.enable()
      if (window.ethereum.chainId !== CHAIN_ID) {
          await self.switchToMainnet() // 切换到网络
          if (window.ethereum.chainId !== CHAIN_ID) {
            self.setState({ ...self.state, connectionStatus: false, wrongNetwork: true, notifyLabel: WRONG_NETWORK })
          }else{
            self.getBlockchainData()
          }     
      } else {
        self.getBlockchainData()
      }
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider)
      self.getBlockchainData()
      self.setState({ ...self.state, connectionStatus: true })
    } else {
      self.setState({
        ...self.state,
        walletInstalledStatus: false,
        connectionStatus: false,
        notifyLabel: METAMASK_UNINSTALLED,
      })
    }
  }

  switchToMainnet = async() => {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: CHAIN_ID }], // 网络的网络ID为0x1
      });
    } catch (error) {
      // 如果切换失败，尝试添加网络
      if (error.code === 4902) {
        try {
          // 添加网络
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [{
              chainId: CHAIN_ID,
              chainName: NETWORK_NAME,
              nativeCurrency: {
                name: 'Bitcoin',
                symbol: 'BTC',
                decimals: 18,
              },
              rpcUrls: [RPC_URL],
              blockExplorerUrls: [BLOCK_EXPLORE_URL],
            }],
          });
        } catch (error) {
          // 处理添加网络失败的情况
          console.error('添加网络时出错：', error);
        }
      } else {
        // 处理其他错误
        console.error('切换到网络时出错：', error);
      }
    }
  }

  getBlockchainData = async () => {
    let accounts = await window.web3.eth.getAccounts()
    let _account = undefined;
    if(accounts != undefined && accounts[0] != undefined){
      _account = {address: accounts[0]}
    }
    const nftToken = new web3.eth.Contract(NFT.abi, REACT_APP_NFT_CONTRACT_ADDRESS)
    this.setState({
      ...this.state,
      connectionStatus: true,
      wrongNetwork: false,
      notifyLabel: SUCCSESS_CONNECTED,
      account: _account,
      nftToken: nftToken,
    })
  }

  disconnect = async () => {
    this.setState({ ...self.state, connectionStatus: false, wrongNetwork: false, account: undefined, notifyLabel: DISCONNECTED })
  }

  render() {
    return (
      <Web3Context.Provider
        value={{
          connectionStatus: this.state.connectionStatus,
          wrongNetwork: this.state.wrongNetwork,
          notifyLabel: this.state.notifyLabel,
          account: this.state.account,
          walletInstalledStatus: this.state.walletInstalledStatus,
          nftToken: this.state.nftToken,
          loadWeb3: this.loadWeb3,
          disconnect: this.disconnect,
        }}
      >
        {this.props.children}
      </Web3Context.Provider>
    )
  }
}
