/*eslint-disable*/
import React, { useContext } from 'react'
import { HashLink as Link } from 'react-router-hash-link'
import { useHistory } from 'react-router-dom'
import { List, ListItem, Typography, withStyles, Button } from '@material-ui/core'
import { useNavigation } from 'hook/Context/NavigationContext'
import { Web3Context } from 'utils/Web3Provider'
import { ConnectButton } from '@ant-design/web3'
import { ConfigProvider } from 'antd'
import toast from 'react-hot-toast'

import { makeStyles } from '@material-ui/core/styles'
import styles from 'assets/jss/components/Header/headerLinksStyle.js'
import discord from 'assets/images/Home/discord.svg'
import twitter from 'assets/images/Home/twitter.svg'
import document from 'assets/images/Home/document.svg'

const useStyles = makeStyles(styles)

const WalletButton = withStyles(() => ({
  root: {
    color: '#000000',
    backgroundColor: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#CCCCCC',
    },
    // fontWeight: '800',
    borderRadius: '25px',
    // padding: '10px 15px',
    textTransform: 'none',
    fontFamily: 'sans-serif',
    width: '180px',
  },
}))(ConnectButton)

const HeaderLinks = () => {
  const classes = useStyles()
  const history = useHistory()

  const {
    connectionStatus,
    wrongNetwork,
    notifyLabel,
    account,
    walletInstalledStatus,
    loadWeb3,
    disconnect,
    nftToken,
  } = useContext(Web3Context)

  // context variables
  const { navigation, setNavigation } = useNavigation()

  // handlers
  const handleGotoNav = path => {
    setNavigation(path)
    // history.push('/#' + path)
  }

  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <Link
          to="/home#buyNow"
          onClick={() => handleGotoNav('buyNow')}
          className={navigation === 'buyNow' ? classes.selectedText : classes.text}
          spy={1}
          smooth={true}
          offset={5}
          duration={500}
        >
          <Typography className={classes.buyNow}>MINT NOW</Typography>
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: '#8442ff',
            },
          }}
        >
          <ConnectButton
            type="primary"
            account={account}
            onConnectClick={() => {
              loadWeb3()
            }}
            onDisconnectClick={() => {
              if (window.ethereum && window.ethereum.isConnected()) {
                disconnect()
              }
            }}
            quickConnect={true}
          />
        </ConfigProvider>
      </ListItem>
      <ListItem className={classes.listItem}>
        <a
          disabled={connectionStatus || wrongNetwork}
          href="https://doc.merlinsirens.xyz/"
          className={classes.text}
          target="_blank"
        >
          <img src={document} className={classes.socialAvatar} />
        </a>
      </ListItem>
      <ListItem className={classes.listItem}>
        <a target="_blank" href="https://twitter.com/MerlinSirensNFT">
          <img src={twitter} className={classes.socialAvatar} />
        </a>
      </ListItem>
      <ListItem className={classes.listItem}>
        <a target="_blank" href="https://discord.gg/yTQKH7HYbb">
          <img src={discord} className={classes.socialAvatar} />
        </a>
      </ListItem>
    </List>
  )
}

export default HeaderLinks
