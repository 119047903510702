// export const REACT_APP_CHAINID = '0x7a69'  //hardhat local
// export const REACT_APP_RPC_URL = 'http://localhost:8545'
////Merlin Test
// export const REACT_APP_NETWORK_NAME = "Merlin Testnet"
// export const REACT_APP_CHAINID = '0xa7b14'
// export const REACT_APP_RPC_URL = 'https://testnet-rpc.merlinchain.io'
// export const REACT_APP_BLOCK_EXPLORE_URL = 'https://testnet-scan.merlinchain.io'
////Merlin Main
export const REACT_APP_NETWORK_NAME = "Merlin Mainnet"
export const REACT_APP_CHAINID = '0x1068'
export const REACT_APP_RPC_URL = 'https://rpc.merlinchain.io'
export const REACT_APP_BLOCK_EXPLORE_URL = 'https://scan.merlinchain.io'

export const REACT_APP_NFT_CONTRACT_ADDRESS = '0x914D296844BdcE5b55841318a5f63a1d6eD9bcC4'
export const NFT_DISCOUNT_PRICE = 0.0003;
export const NFT_NORMAL_PRICE = 0.0006;
