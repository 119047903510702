import React from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import styles from 'assets/jss/pages/Home/homeSectionStyle'
import globalStyles from 'assets/jss/PLUTEX'

const useStyles = makeStyles(styles)
const useGlobalStyles = makeStyles(globalStyles)

const HomeSection = () => {
  const classes = useStyles()
  const globalClasses = useGlobalStyles()
  return (
    <Box className={classes.homeSectionArea}>
      <Box className={classes.homeContainer}>
        <Box className={classes.container}>
          {/* <Box className={classes.homeImage}> */}
          <Box className={classes.content}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography className={classes.title}>What is MerlinSirens?</Typography>
              <Typography className={globalClasses.text17}>
                Welcome to the Merlin World, where we present a limited edition trove of 10K NFTs, each a tribute to the
                legendary pioneers of Merlin's lore. Within this collection, we invite you to relive the epic sagas of
                intrepid explorers who ventured across the tumultuous seas, not just navigating the fierce waves but
                also encountering the enigmatic Sirens in their quest for hidden treasures.
              </Typography>
              <Typography className={globalClasses.text17}>
                Each MerlinSirens NFT is a unique legend in itself, embodying the wisdom, courage, and curiosity of
                Merlin's trailblazers. These digital artifacts are more than mere images; they are narratives, pieces of
                history, and testimonies to the incredible encounters between sailors and Sirens.
              </Typography>
              <Typography className={globalClasses.text17}>
                We cordially invite you to join this mysterious voyage, to explore tales buried by waves and storms, to
                seek treasures hidden in the ocean's depths. MerlinSirens NFTs offer more than a collecting opportunity;
                they provide an immersive experience into humanity's oldest legends, a stirring of the soul, a journey
                of dreams.
              </Typography>
              <Typography className={globalClasses.text17}>
                So, prepare to embark on our ship, as we unravel the secrets of Merlin's vanguards and listen to the
                Siren's song. MerlinSirens—this is not just a set of NFTs, but an adventure, a legend, a personal epic
                waiting to be claimed.
              </Typography>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default HomeSection
